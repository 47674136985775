<template>
  <div class="tab-content">
      <div class="pagenamPT">
        <h3 class="pagename2">{{ $t('front.cash.changesInMoney') }}</h3>
      </div>
      <div class="partnerTop">
        <div class="search-check">
          <div class="title"><span>{{ $t('front.common.detailCondition') }} :</span></div>
          <div class="content">
            <div class="chk">
              <label for="a">{{ $t('front.gameCategory.all') }}</label>
              <input type="checkbox" id="a" :checked="selectAll" @click="toggleAllCheckboxes" />
            </div>
            <template v-for="item in cashTypeList" :key="item.code">
              <div class="chk">
                <label :for="item.code">{{ item.label }}</label>
                <input type="checkbox" :id="item.code" :checked="item.checked" @click="toggleCheckbox(item)" />
              </div>
            </template>
          </div>
        </div>
        <div class="fx-center-end">
          <input type="text" class="input" :placeholder="$t('front.common.memId')" v-model="searchMemId"/>
          <date-filter-Pt :startDate="startDate"
                          :endDate="endDate"
                          @update="updateDate"
                          @search="loadLog" />
          <div class="ck">
            <input type="checkbox" id="z" :checked="oldYn === 'Y'" @click="setOldYn"/>
            <label for="z">{{ $t('front.common.searchData') }}</label>
          </div>
        </div>
      </div>
      <div class="strTableWrap">
        <div class="strTablescr">
            <div class="strTablePC">
              <table class="strTablePT">
                <colgroup>
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                  <col style="width:10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('front.board.regDate') }}</th>
                    <th>{{ $t('front.common.completeTime') }}</th>
                    <th>{{ $t('front.common.memId') }}</th>
                    <th>{{ $t('front.common.nickName') }}</th>
                    <th>{{ $t('front.common.category') }}</th>
                    <th>{{ $t('front.common.moneyBefore') }}</th>
                    <th>{{ $t('front.common.changeable') }}</th>
                    <th>{{ $t('front.common.moneyAfter') }}</th>
                    <th>{{ $t('front.board.status') }}</th>
                    <th>{{ $t('front.give.etc') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="cashTransLogs && cashTransLogs.length > 0">
                    <template v-for="cash in cashTransLogs" :key="cash.cashIdx">
                      <tr>
                        <td>{{ cash.regDate.replace('T', ' ') }}</td>
                        <td>{{ cash.updDate.replace('T', ' ') }}</td>
                        <td>{{ cash.memId }}</td>
                        <td class="nick">{{ cash.memNick }}</td>
                        <td>{{ cash.typeDesc }}</td>
                        <td>{{ thousand(cash.preCashAmt || 0) }}</td>
                        <td class="minusAmt">{{ thousand(cash.cashAmt || 0) }}</td>
                        <td>{{ thousand(cash.postCashAmt || 0) }}</td>
                        <td>{{ cash.statusDesc }}</td>
                        <td><span class="txt">{{ cash.memo }}</span></td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="10">{{ $t('front.common.notFoundList') }}</td>
                    </tr>
                  </template>

                </tbody>
              </table>
            </div>
            <div class="strTableM">
              <div class="strTablePTM">
                <ul>
                  <template v-if="cashTransLogs && cashTransLogs.length > 0">
                    <template v-for="cash in cashTransLogs" :key="cash.cashIdx">
                      <li>
                        <em>등록일시</em>
                        <div>{{ cash.regDate.replace('T', ' ') }}</div>
                      </li>
                      <li>
                        <em>처리 완료 시간</em>
                        <div>{{ cash.updDate.replace('T', ' ') }}</div>
                      </li>
                      <li>
                        <em>아이디</em>
                        <div>{{ cash.memId }}</div>
                      </li>
                      <li>
                        <em>닉네임</em>
                        <div class="nick">{{ cash.memNick }}</div>
                      </li>
                      <li>
                        <em>유형</em>
                        <div>{{ cash.typeDesc }}</div>
                      </li>
                      <li>
                        <em>변동 전 머니</em>
                        <div>{{ thousand(cash.preCashAmt || 0) }}</div>
                      </li>
                      <li>
                        <em>변동 머니</em>
                        <div class="minusAmt">{{ thousand(cash.cashAmt || 0) }}</div>
                      </li>
                      <li>
                        <em>변동 후 머니</em>
                        <div>{{ thousand(cash.postCashAmt || 0) }}</div>
                      </li>
                      <li>
                        <em>상태</em>
                        <div>{{ cash.statusDesc }}</div>
                      </li>
                      <li>
                        <em>비고</em>
                        <div><span class="txt">{{ cash.memo }}</span></div>
                      </li>
                    </template>
                  </template>
                  <template v-else>
                    <li>{{ $t('front.common.notFoundList') }}</li>
                  </template>
                </ul>
              </div>
            </div>
        </div>
      </div>
    <pagination v-if="pageInfo"
                :pageNum="pageInfo.page"
                :pageSize="pageInfo.count_per_list"
                :totalCount="pageInfo.tatal_list_count"
                :className="'partnerPaging'"
                @goToPage="loadLog" />
  </div>
</template>

<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import Pagination from '@/components/ui/Pagination.vue'
import { mapState } from 'vuex'
import { getCashTransLogV2 } from '@/api/v2/cash'
import { getCode } from '@/api/common'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
export default {
  name: 'cashListMy',
  components: { DateFilterPt, Pagination },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      cashTypeList: [],
      cashTypeEtcList: [],
      cashTransLogs: null,
      selectAll: true,
      searchMemId: '',
      oldYn: 'N',
      startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
      endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
    }
  },
  async created () {
    let result = await getCode('typeInfo', 'cashType')
    console.log(result)
    const firstSelectCode = ['1', '-1', '-19', '19', '-3', '3']
    result.forEach(item => {
      if (firstSelectCode.includes(item.code)) {
        item.checked = true
      }
      item.label = this.$i18n.locale === 'ko' ? item.codeName : item['codeName' + this.$i18n.locale.toUpperCase()]
    })

    result = result.filter(item => {
      if (item.codeName.indexOf('홀덤') > -1 || item.codeName.indexOf('스포츠') > -1) {
        this.cashTypeEtcList.push(item.code)
        return false
      }
      return true
    })

    result.push({
      checked: false,
      code: 'etc',
      label: '기타게임'
    })

    this.cashTypeList = result
    console.log(result)

    this.loadLog()
  },
  watch: {
  },
  methods: {
    thousand,
    updateDate (value) {
      this.startDate = value.startDate
      this.endDate = value.endDate
    },
    setOldYn () {
      this.oldYn = this.oldYn === 'Y' ? 'N' : 'Y'
    },
    toggleAllCheckboxes () {
      this.cashTypeList.forEach((checkbox) => {
        if (checkbox.id !== 'a') {
          checkbox.checked = !this.selectAll
        }
      })
      this.selectAll = !this.selectAll
    },
    toggleCheckbox (checkbox) {
      checkbox.checked = !checkbox.checked
      this.selectAll = this.cashTypeList.every((checkbox) => checkbox.checked)
    },
    loadLog (page) {
      console.log(page, this.pageInfo.page)
      if (!page) {
        page = this.pageInfo.page
      }
      if (page === true) {
        page = 1
      }
      this.cashTransLogs = null

      let cashTypeList = this.cashTypeList.filter(item => {
        return item.checked
      }).map(item => {
        return item.checked ? item.code : null
      })

      let isEtc = false
      cashTypeList.forEach(code => {
        if (code === 'etc') {
          isEtc = true
        }
      })

      if (isEtc) {
        cashTypeList = [
          ...cashTypeList,
          ...this.cashTypeEtcList
        ]

        cashTypeList = cashTypeList.filter(item => {
          return item !== 'etc'
        })
      }

      const params = {
        page: page,
        memId: this.searchMemId,
        startDate: this.startDate,
        endDate: this.endDate,
        cashTypeList: cashTypeList.length > 0 ? cashTypeList : null,
        oldYn: this.oldYn
      }
      console.log(params)
      getCashTransLogV2(params).then(res => {
        const result = res.data
        console.log('getCashTransLogV2 : ', params, result)
        if (result.resultCode === '0') {
          this.cashTransLogs = result.data.cashTransLogs
          console.log(this.cashTransLogs)
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.partnerTop {display: flex;align-items: center;justify-content: space-between;margin-bottom: 17px;}
.fx-center-end {display: flex;align-items: center;gap: 10px;}
.selectBox {
  height: 42px !important;
  padding: 12px 11px;
  box-sizing: border-box;
  border: solid 1px #cfcfcf !important;
  font-size: 15px !important;
  margin-bottom: 18px;
}
.input {
  width: 300px;
  height: 42px !important;
  padding: 12px 11px !important;
  box-sizing: border-box;
  border: solid 1px #cfcfcf !important;
  background-color: #fff;
  font-size: 15px !important;
  margin-bottom: 18px;
}
.ck {display: flex;align-items: center;margin-bottom: 18px;}
.ck label {font-size: 12px;font-weight: 800;}
.ck input {padding: 0 !important;height: auto !important;min-width: auto !important;}

.search-check {display: flex;flex-direction: row;font-size: 12px;color: #252930;}
.search-check .content {display: flex;flex-wrap: wrap;gap: 8px 40px;max-width: 765px;}
.search-check .chk {width: 128px;display: flex;align-items: center;gap: 3px;justify-content: end;}
.search-check label {text-align: right;font-size: 13px;}
.search-check input {padding: 0 !important;height: auto !important;min-width: auto !important;}

.txt {white-space: pre-wrap;line-height: 1.5em;}

.partnerInfo {padding: 12px 10px 30px;margin-bottom: 30px;box-sizing: border-box;display: flex;flex-direction: column;gap: 30px;}
h4.title {font-size: 20px;font-weight: bold;letter-spacing: -1px;color: #000;margin-bottom: 10px;}
.partnerInfo ul {display: flex;flex-direction: column;gap: 12px;width: 273px;}
.partnerInfo ul li:first-child {font-size: 15px;font-weight: bold;color: #000;box-shadow: none;border: 0;}
.partnerInfo ul li {    display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e7e7;
  box-sizing: border-box;
  background-color: #fff;font-weight: bold;color: #3b3b3b;}
.partnerInfo ul li em {display: flex;
    align-items: center;
    justify-content: space-between;height: 30px;
  letter-spacing: -0.75px;
  border-bottom: 1px solid #e7e7e7;padding: 0 13px;}
.partnerInfo ul li span {display: flex;
    align-items: center;
    justify-content: space-between;height: 46px;font-size: 13px;letter-spacing: -0.65px;padding: 0 13px;}
.infoView {display: flex;align-items: center;justify-content: center;gap: 50px;}
.btnAdd {display: flex;align-items: center;margin-left: auto;margin-right: 50px;height: 27px;padding: 0 18px;border-radius: 4px;background-color: #363b72;color: #fff;font-size: 13px;}
.btn-select-wrap {display: flex;align-items: center;justify-content: center;}
.btn-select-wrap select {min-width: auto !important;height: 27px !important;border: solid 1px #c9c9c9 !important;padding: 0 0 0 5px !important;border-radius: 4px 0 0 4px !important;}
.btn-select-wrap .btnp {border-radius: 0 4px 4px 0 !important;}
.btnp {
  height: 27px;
  padding: 0px 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #8376d4;
  color: #fff;
  display: flex;
  align-items: center;cursor: pointer;
}
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
.subPT_1 li {
  position: relative;
}
.allBtn {
  background: #e50000;
  color: #fff;
  width: 57px;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #000;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
  .partnerTop {flex-direction: column;gap: 15px;}
  .ck, .datesearch, .fx-center-end .input {margin-bottom: 0;}
  .search-check .chk {width: 109px;gap: 0;}
  .search-check label {font-size: 11px;}
}
@media screen and (max-width: 840px) {
  .search-check {flex-direction: column;}
  .search-check .content {justify-content: center;gap: 5px;}
  .fx-center-end {flex-direction: column;}
  .fx-center-end .input {margin: 0;}
}
</style>
